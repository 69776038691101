import ApplicationController from './application_controller';
import { showLoadingSpinner } from './utilities/loading_spinner';

export default class extends ApplicationController {
  static targets = [
    'noneAnswer',
    'regularAnswer',
    'commentable',
    'endResponse',
    'freeTextField',
    'nextButtonLink',
  ];

  connect() {
    super.connect();

    /*
      If the NavigationComponent does not have a next path,
      - prevent default behavior
      - submit the form through this controller
      - select combination form will be submitted (does not have next path)
    */
    if (this.hasNextButtonLinkTarget) {
      const submitBtn = document.querySelector('.store-button.submit');

      if (this.nextButtonLinkTarget.dataset.nextPath === 'false') {
        this.nextButtonLinkTarget.addEventListener('click', (e) => {
          e.preventDefault();
          if (submitBtn.disabled === false) {
            this.submitForm();
          }
        });
      }
    }

    document.addEventListener('auto-advance-to-next-question', this.submitForm);
    document.addEventListener(
      'enable-form-submit-arrow',
      this.activateArrowAndEnableSubmit.bind(this),
    );
    document.addEventListener(
      'disable-form-submit-arrow',
      this.deactivateArrowAndEnableSubmit.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener(
      'auto-advance-to-next-question',
      this.submitForm,
    );
    document.removeEventListener(
      'enable-form-submit-arrow',
      this.activateArrowAndEnableSubmit.bind(this),
    );
    document.removeEventListener(
      'disable-form-submit-arrow',
      this.deactivateArrowAndEnableSubmit.bind(this),
    );
  }

  initialize() {
    this.checkOnLoad();
    this.shouldEnableSubForm();
    this.handleQuantitySelectionTooltips();
  }

  disableSubmit() {
    const submitBtn = document.querySelector('.store-button.submit');

    if (submitBtn) {
      submitBtn.disabled = true;
      submitBtn.removeEventListener('click', this.submitForm);
    }
  }

  enableSubmit() {
    const submitBtn = document.querySelector('.store-button.submit');

    if (submitBtn) {
      submitBtn.addEventListener('click', this.submitForm);
    }
  }

  activateArrowAndEnableSubmit() {
    this.activateNextButton();
    this.enableSubmit();
  }

  deactivateArrowAndEnableSubmit() {
    this.deactivateRightArrow();
    this.disableSubmit();
  }

  clickSubmitBtn() {
    const submitBtn = document.querySelector('.store-button.submit');

    if (submitBtn) {
      submitBtn.click();
    }
  }

  submitForm() {
    if (window.questionnaireHardStop) return;
    const submitBtn = document.querySelector('.store-button.submit');
    submitBtn.style.pointerEvents = 'none';
    submitBtn.style.cursor = 'not-allowed';
    submitBtn.disabled = true;
    document.querySelector('form').submit();
  }

  checkMultiAnswer() {
    const el = $('.multi_answer_cb');

    el.each((idx) => {
      if ($(el[idx]).prop('checked') === true) {
        $(el[idx])
          .prop('checked', true)
          .parent('li')
          .addClass('border-store-darkgray font-bold');

        this.activateNextButton();
        this.enableSubmit();
        $(el[idx])
          .nextAll('.more-info:first')
          .removeClass('hidden')
          .addClass('');

        const li = $(el[idx]).parent('li').addClass('mb-0');
        const moreInfo = li.next('.more-info');

        if (moreInfo.length) {
          moreInfo.addClass('mb-0').removeClass('hidden');
        }
      }
    });
  }

  checkSingleChoice() {
    const el = $('.qa-radio');

    el.each((idx) => {
      if ($(el[idx]).prop('checked') === true) {
        $(el[idx])
          .prop('checked', true)
          .prev()
          .addClass('font-bold !border-store-darkgray bg-green');

        this.activateNextButton();
        this.enableSubmit();
        $(el[idx])
          .nextAll('.more-info:first')
          .removeClass('hidden')
          .parent()
          .find('label')
          .addClass('mb-0');
      }
    });

    this.checkDosageQuantityRadios();
  }

  checkFileUploads() {
    if (document.querySelector('li.image_item') !== null) {
      this.activateNextButton();
      this.enableSubmit();
    }
  }

  checkOnLoad() {
    this.checkMultiAnswer();
    this.checkSingleChoice();
    this.checkFileUploads();
    $(
      "input[name$='store_order[line_items_attributes][quantity]']:checked",
    ).trigger('focus');
  }

  activateNextButton() {
    if (!this.shouldEnableSectionForm()) return;
    const submitBtnEl = document.querySelector('.store-button.submit');
    if (!submitBtnEl) return;
    if (this.checkHardStops()) return;
    submitBtnEl.disabled = false;
  }

  deactivateRightArrow() {
    if (!this.shouldEnableSectionForm()) return;
    const submitBtnEl = document.querySelector('.store-button');
    if (!submitBtnEl) return;
    if (!submitBtnEl.dataset.rightarrowsubmitDisabled) return;

    const newSrc = submitBtnEl.dataset.rightarrowsubmitDisabled;
    const rightArrowEl = document.querySelector('.right-arrow');

    rightArrowEl.setAttribute('alt', 'right-arrow-inactive');
    rightArrowEl.setAttribute('src', newSrc);
  }

  disableEnableSurveySubmit() {
    if (window.questionnaireHardStop) return;

    if (!this.shouldEnableSectionForm()) return;

    $('.qa-radio, .multi_answer_cb, .doses-radio').each(() => {
      if (
        $('.qa-radio:checked, .multi_answer_cb:checked, .doses-radio:checked')
      ) {
        this.enableSubmit();
        this.activateNextButton();
      } else {
        this.disableSubmit();
      }
    });
  }

  selectMultipleAnswers(event) {
    event.preventDefault();

    if (event.target.nodeName !== 'LABEL') return;

    const selectedLabel = event.target;
    const selectedInput =
      selectedLabel.parentNode.getElementsByTagName('input')[0];

    const answers = this.regularAnswerTargets;
    const noneAnswers = this.noneAnswerTargets;
    const endResponses = this.endResponseTargets;

    if (noneAnswers.includes(selectedLabel)) {
      answers.forEach((answer) => this.deselectOption(answer));
      endResponses.forEach((endResponse) => this.deselectOption(endResponse));
    }

    if (answers.includes(selectedLabel)) {
      noneAnswers.forEach((answer) => this.deselectOption(answer));
    }

    if (endResponses.includes(selectedLabel)) {
      noneAnswers.forEach((answer) => this.deselectOption(answer));
    }

    this.displayMoreInfo($(selectedInput));

    if (
      selectedInput.checked &&
      !this.autoAdvanceAnswerSelected(selectedLabel)
    ) {
      this.deselectOption(selectedLabel);
    } else {
      this.selectOption(selectedLabel);
    }

    this.checkHardStops();

    if (this.autoAdvanceAnswerSelected(selectedLabel)) {
      document.dispatchEvent(this.advanceToNextQuestionEvent);
    }
  }

  /* eslint no-param-reassign: "error" */
  selectOption(labelElement) {
    if (labelElement.nodeName === 'LI') return;
    labelElement.parentNode.getElementsByTagName('input')[0].checked = true;
    labelElement.parentNode.classList.add('border-store-darkgray', 'font-bold');
  }

  /* eslint no-param-reassign: "error" */
  deselectOption(labelElement) {
    if (labelElement.nodeName === 'LI') return;
    labelElement.parentNode.getElementsByTagName('input')[0].checked = false;
    labelElement.parentNode.classList.remove(
      'border-store-darkgray',
      'font-bold',
    );
  }

  toggleRadioButton(event) {
    window.questionnaireHardStop = false;

    const selected = $(event.target);
    const unselected = $(this.element)
      .find('input[type=radio]:not(checked)')
      .not(`#${selected.attr('id')}`);

    this.closeMoreInfos(event.target);

    this.displayMoreInfo(selected);

    selected
      .attr('checked', 'checked')
      .parent('li')
      .children('label')
      .addClass('mb-0 !border-store-darkgray font-bold');

    unselected
      .removeAttr('checked')
      .parent('li')
      .children('label')
      .removeClass('!border-store-darkgray font-bold');

    if (this.autoAdvanceAnswerSelected(event.target)) {
      document.dispatchEvent(this.advanceToNextQuestionEvent);
    }
  }

  toggleMultipleRadioButton(event) {
    const selected = $(event.target);
    const unselected = selected
      .closest('radiogroup')
      .find('input[type=radio]:not(checked)')
      .not(`#${selected.attr('id')}`);

    selected
      .attr('checked', 'checked')
      .prev()
      .addClass('!border-store-darkgray font-bold');
    unselected
      .removeAttr('checked')
      .prev()
      .removeClass('!border-store-darkgray font-bold');
  }

  // Hide/show more info area for answer option
  toggleUserAnswerFreeText(event) {
    const noneAnswer = $(event.target).data('none-answer');

    if (noneAnswer) {
      $(this.commentableTarget).css('display', 'none');
      return;
    }

    $(this.commentableTarget).toggle('hidden');
  }

  hardStop(event) {
    const selectedLabel = event.target;

    const endResponse =
      selectedLabel.parentNode.getElementsByTagName('input')[0];

    if (endResponse.checked) {
      window.questionnaireHardStop = true;

      this.disableSubmit();

      this.callStimulusAction({
        cb: () => {
          this.stimulate('Store::Questionnaires::HardStop#show');
        },
      });
    } else {
      this.deselectOption(selectedLabel);
    }
  }

  checkHardStops() {
    const endResponses = this.endResponseTargets;

    const hasHardStop = Array.from(endResponses).some((label) => {
      const inputId = label.getAttribute('for');
      const input = document.getElementById(inputId);
      return input && input.checked;
    });

    if (!hasHardStop) {
      window.questionnaireHardStop = false;
    }

    return hasHardStop;
  }

  checkDosageQuantityRadios(e) {
    if (e !== undefined && e.type === 'click') showLoadingSpinner();

    const dosages = $("input[name$='variant_id']");
    dosages.each((idx) => {
      if ($(dosages[idx]).prop('checked')) {
        $(dosages[idx])
          .prop('checked', true)
          .siblings('label')
          .addClass('bg-store-primary text-store-amberblack')
          .text('Selected combination');

        this.activateNextButton();
        this.enableSubmit();

        if (e !== undefined && e.type === 'click') {
          setTimeout(() => this.clickSubmitBtn(), 300);
        }
      }
    });

    const quantities = $('.doses-radio');

    quantities.each((idx) => {
      if ($(quantities[idx]).prop('checked')) {
        this.activateNextButton();
        this.enableSubmit();
      }
    });
  }

  checkFrequencyQuantityRadios(e) {
    if (e !== undefined && e.type === 'click') showLoadingSpinner();
    const frequencies = $(
      "input[type=radio][name$='store_order[line_items_attributes][quantity]'],input[type=radio][name$='store_order[line_items_attributes][variant_id]']",
    );

    frequencies.each((idx) => {
      const frequencySelection = $(frequencies[idx]);
      if (frequencySelection.prop('checked')) {
        frequencySelection
          .prop('checked', true)
          .parentsUntil('.frequency-card')
          .parent()
          .removeClass('bg-white');
        this.activateNextButton();
        this.enableSubmit();
        if (e !== undefined && e.type === 'click') {
          setTimeout(() => this.clickSubmitBtn(), 300);
        }
      } else {
        frequencySelection
          .prop('checked', false)
          .parentsUntil('.frequency-card')
          .parent()
          .addClass('bg-white');
      }
    });

    this.disableEnableSurveySubmit();
  }

  freeTextFieldTargetConnected() {
    this.activateNextButton();
    this.enableSubmit();
  }

  displayMoreInfo(input) {
    const hasMoreInfo = input.parent('li').data('needMoreInfo');

    if (hasMoreInfo) {
      input.parent('li').addClass('mb-0');
      const moreInfo = $(input).nextAll('.more-info:first').length
        ? $(input).nextAll('.more-info:first')
        : $(input).parent('li').nextAll('.more-info:first');

      moreInfo.toggle('hidden');
    }
  }

  closeMoreInfos(clickEl) {
    const inputs = $('.qa-radio');

    inputs.each(function () {
      if (clickEl === this) return;
      $(this).nextAll('.more-info:first').addClass('hidden').hide();
    });
  }

  shouldEnableSectionForm() {
    const sectionId = $('#user_answer_form_section_id');

    if (!sectionId.length) return true;

    const qaRadioChecked = $('.qa-radio:checked').length;

    const questionIds = $('#user_answer_form_question_ids')
      .val()
      .split(' ').length;

    return qaRadioChecked === questionIds;
  }

  shouldEnableSubForm() {
    const qaRadioChecked = $('.radio-lg:checked').length;

    if (qaRadioChecked) {
      this.activateNextButton();
      this.enableSubmit();
    }
  }

  get advanceToNextQuestionEvent() {
    return new CustomEvent('auto-advance-to-next-question');
  }

  autoAdvanceAnswerSelected(element) {
    return element.dataset.autoAdvance === 'true';
  }

  toggleSubscriptionSelectionCheckMarks(e) {
    const { checkWhiteSrc } = this.element.dataset;
    const { checkDarkSrc } = this.element.dataset;

    // White check marks for selected sub type
    e.currentTarget
      .querySelectorAll('img')
      .forEach((img) => $(img).attr('src', checkWhiteSrc));

    // Black check marks for non-selected sub types
    document
      .querySelectorAll('input[type=radio]:not(:checked)')
      .forEach((input) => {
        const imgs = input.parentElement.querySelectorAll('img');
        imgs.forEach((img) => $(img).attr('src', checkDarkSrc));
      });
  }

  handleQuantitySelectionTooltips() {
    if (this.element.dataset.step !== 'quantity_selection') return;

    const isQuarterly = this.element.dataset.isQuarterly === 'true';
    const packsPerShipment = isQuarterly ? [18, 36, 54, 72] : [1, 2, 3, 4];

    packsPerShipment.forEach((num) => {
      const experiences = isQuarterly === true ? num : num * 6;
      const days = isQuarterly === true ? 90 : 30;
      const $tooltip = $(`#${num}-packs-per-shipment-tooltip`);

      $tooltip.tooltipster({
        theme: 'rugiet',
        position: window.matchMedia('(max-width: 767px)').matches
          ? 'top'
          : 'right',
        content: $(
          `<div style='padding: 10px;'><div style='width: 90%;'><strong>${experiences} experiences over ${days} days</strong></div></div></div>`,
        ),
        trigger: 'custom',
        triggerOpen: {
          mouseenter: true,
          touchstart: true,
        },
        triggerClose: {
          mouseleave: true,
          tap: true,
        },
      });
    });

    $('.not-available-tooltip').tooltipster({
      theme: 'rugiet',
      position: window.matchMedia('(max-width: 767px)').matches
        ? 'top'
        : 'right',
      content: $(
        "<div style='padding: 10px;'><p style='font-weight: bold;'>Recommended to have the best experiences </br> in one month.</p></div>",
      ),
      contentCloning: true,
      trigger: 'custom',
      triggerOpen: {
        mouseenter: true,
        touchstart: true,
      },
      triggerClose: {
        mouseleave: true,
        tap: true,
      },
    });
  }
}
